import { useContext, useState, useEffect, createContext } from "react";
import { Auth, Hub } from "aws-amplify";
import "./authForms.scss";

const AuthContext = createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const [auth, setAuth] = useState();
	const [loading, setLoading] = useState(true);

	function setCurrentUser() {
		setLoading(true);
		Auth.currentAuthenticatedUser()
			.then((user) => setAuth(user))
			// Returns error is no authenticated user
			.catch(() => setAuth())
			.finally(() => setLoading(false));
	}

	useEffect(() => {
		setCurrentUser();
		// Listen for auth state change
		Hub.listen("auth", setCurrentUser);
	}, []);

	return <AuthContext.Provider value={auth}>{!loading && children}</AuthContext.Provider>;
}
