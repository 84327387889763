import "./LoginScreen.scss";

import LoginForm from "../../auth/Login/LoginForm";
import Logo from "../../components/Logo/Logo";

export default function LoginScreen() {
	return (
		<main className="login-screen">
			<section className="login-section">
				<Logo />
				<LoginForm />
			</section>

			<section className="hero-section hide-for-mobile">
				<iframe
					title="ProductName"
					frameborder="0"
					allowfullscreen
					mozallowfullscreen="true"
					webkitallowfullscreen="true"
					allow="autoplay; fullscreen; xr-spatial-tracking; clipboard-write;"
					xr-spatial-tracking
					execution-while-out-of-viewport
					execution-while-not-rendered
					web-share
					width="100%"
					height="100%"
					src="https://reybeam.reydar.com/?config_uuid=e24a56f4-f247-11ec-b939-0242ac120002"
				></iframe>
			</section>
		</main>
	);
}
