import "./AppLayout.scss";
import Navigation from "../../components/Navigation/Navigation";

export default function AppLayout({ children }) {
	return (
		<div className="app-wrapper">
			<Navigation />
			<div className="app-content">{children}</div>
		</div>
	);
}
