import { useState } from "react";
import { Auth } from "aws-amplify";
import { emailValidator } from "../validators.js";

import TextInput from "../../components/Inputs/Text/TextInput";
import PrimaryButton from "../../components/Buttons/Primary/PrimaryButton";

export default function ForgotPasswordForm({ setCodeSent, setEmail }) {
	const [throttle, setThrottle] = useState(false);
	const [emailError, setEmailError] = useState();

	function validateInputs(email) {
		const emailError = emailValidator(email);
		setEmailError(emailError);
		return emailError ? false : true;
	}

	function handleSubmit(event) {
		event.preventDefault();
		const data = new FormData(event.target);
		const email = data.get("email");
		const isValid = validateInputs(email);
		if (isValid && !throttle) sendResetEmail(email);
	}

	function sendResetEmail(email) {
		setThrottle(true);
		// Send confirmation code to user's email
		Auth.forgotPassword(email)
			.then(() => setCodeSent(true))
			.then(() => setEmail(email))
			.catch((error) => setEmailError(error.message))
			.finally(() => setThrottle(false));
	}

	return (
		<form className="auth-form" onSubmit={handleSubmit}>
			<TextInput type="email" name="email" placeholder="Email address" error={emailError} />
			<PrimaryButton>Send email</PrimaryButton>
		</form>
	);
}
