import "./TextInput.scss";
import ErrorMessage from "../../Error/ErrorMessage";

export default function TextInput({ type, name, placeholder, error, autocomplete }) {
	return (
		<div className="text-input">
			<label className="text-input__inner">
				<input
					className="text-input__input"
					name={name}
					placeholder=" "
					formNoValidate={true}
					type={type ? type : "text"}
					data-error={error && true}
					autoComplete={autocomplete && autocomplete}
				/>
				<span className="text-input__placeholder">{placeholder}</span>
			</label>
			<ErrorMessage message={error} />
		</div>
	);
}
