export function emailValidator(email) {
	if (!email) {
		return "Email is required";
	} else if (!new RegExp(/\S+@\S+\.\S+/).test(email)) {
		return "Incorrect email format";
	}
	return null;
}

export function passwordValidatorSignup(password) {
	if (!password) {
		return "Password is required";
	} else if (password.length < 8) {
		return "Password must have a minimum 8 characters";
	} else if (!new RegExp(/[A-Z]/).test(password)) {
		return "Password must contain at least one uppercase letter";
	} else if (!new RegExp(/[a-z]/).test(password)) {
		return "Password must contain at least one lowercase letter";
	} else if (!new RegExp(/[0-9]/).test(password)) {
		return "Password must contain at least one number";
	} else if (!new RegExp(/[-._!"`'#%&,:;<>=@{}~$()*+/\\|?[\]^]+/).test(password)) {
		return "Password must contain at least one special character";
	}
	return null;
}

export function confirmationCodeValidator(code) {
	if (!code) {
		return "Confirmation code is required";
	} else if (!new RegExp(/^[0-9]*$/).test(code)) {
		return "Code can only contain numbers";
	} else if (code.length < 6) {
		return "Code has maximum of 6 characters";
	}
	return null;
}

export function passwordValidatorLogin(password) {
	if (!password) {
		return "Password is required";
	}
	return null;
}

export function nameValidator(name) {
	if (!name) {
		return "Name is required";
	}
	return null;
}
