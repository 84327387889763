import "./PrimaryButton.scss";
import { Link } from "react-router-dom";

export default function PrimaryButton({ type, children, onClick, className, route }) {
	if (!route) {
		return (
			<button type={type} onClick={onClick} className={`button button--primary ${className}`}>
				{children}
			</button>
		);
	}

	return (
		<Link to={route} className={`button button--primary ${className}`}>
			{children}
		</Link>
	);
}
