import "./Navigation.scss";
import { Auth } from "aws-amplify";

import Drawer from "../Drawer/Drawer";
import Logo from "../Logo/Logo";
import DrawerItem from "../Drawer/Item/DrawerItem";

import { ReactComponent as OverviewIcon } from "../../assets/icons/home.svg";
import { ReactComponent as ProductsIcon } from "../../assets/icons/folder.svg";
import { ReactComponent as ConfigureIcon } from "../../assets/icons/box.svg";
import { ReactComponent as ViewerIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as SupportIcon } from "../../assets/icons/life-buoy.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import { ReactComponent as AccountIcon } from "../../assets/icons/user.svg";
import { ReactComponent as SignoutIcon } from "../../assets/icons/log-out.svg";
import { useNavigate } from "react-router-dom";

export default function Navigation() {
	const navigate = useNavigate();

	function signOut() {
		Auth.signOut().then(() => navigate("/"));
	}

	function goToSupport() {
		window.open("https://forms.clickup.com/2180794/f/22hnu-18168/J0EPQVOK8WVWIELNY5", "_blank");
	}

	return (
		<Drawer className="navigation">
			<Logo />
			<ul className="navigation__top">
				<DrawerItem label="Overview" icon={<OverviewIcon />} route="/" />
				<DrawerItem label="Product Warehouse" icon={<ProductsIcon />} route="/products" />
				<DrawerItem label="Configurator" icon={<ConfigureIcon />} route="/configurator" />
				<DrawerItem label="Viewer" icon={<ViewerIcon />} route="/viewer" />
				<DrawerItem label="Support" icon={<SupportIcon />} onClick={goToSupport} />
			</ul>

			<ul className="navigation__bottom">
				<DrawerItem label="Settings" icon={<SettingsIcon />} route="/settings" />
				<DrawerItem label="Account" icon={<AccountIcon />} route="/account" />
				<DrawerItem label="Sign Out" icon={<SignoutIcon />} onClick={signOut} />
			</ul>
		</Drawer>
	);
}
