import { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

import {
	passwordValidatorSignup as passwordValidator,
	confirmationCodeValidator as codeValidator,
} from "../validators.js";

import TextInput from "../../components/Inputs/Text/TextInput";
import PrimaryButton from "../../components/Buttons/Primary/PrimaryButton";
import PasswordInput from "../../components/Inputs/Password/PasswordInput";

export default function ResetPasswordForm({ email }) {
	const navigate = useNavigate();

	const [throttle, setThrottle] = useState(false);
	const [codeError, setCodeError] = useState();
	const [passwordError, setPasswordError] = useState();

	function validateInputs(code, password) {
		const codeError = codeValidator(code);
		setCodeError(codeError);
		const passwordError = passwordValidator(password);
		setPasswordError(passwordError);

		return passwordError || codeError ? false : true;
	}

	function resetPassword(code, password) {
		setThrottle(true);
		Auth.forgotPasswordSubmit(email, code, password)
			.then(() => navigate("/", { replace: true }))
			.catch(console.log)
			.finally(() => setThrottle(false));
	}

	function handleSubmit(event) {
		event.preventDefault();

		const data = new FormData(event.target);
		const code = data.get("code");
		const password = data.get("password");

		const isValid = validateInputs(code, password);
		if (isValid && !throttle) resetPassword(code, password);
	}

	return (
		<form className="auth-form" onSubmit={handleSubmit}>
			<TextInput name="code" placeholder="Confirmation code" error={codeError} />
			<PasswordInput error={passwordError} autocomplete="new-password" />
			<PrimaryButton>Reset password</PrimaryButton>
		</form>
	);
}
