import "./DrawerItem.scss";
import { Link, useLocation } from "react-router-dom";

export default function DrawerItem({ label, icon, onClick, route }) {
	const location = useLocation();
	const isActive = location?.pathname === route;

	if (!route) {
		return (
			<li>
				<button className="drawer-item" onClick={onClick}>
					{icon}
					<span className="drawer-item__label">{label}</span>
				</button>
			</li>
		);
	}

	return (
		<li>
			<Link className={`drawer-item ${isActive && "active"}`} to={route}>
				{icon}
				<span className="drawer-item__label">{label}</span>
			</Link>
		</li>
	);
}
