import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/Buttons/Primary/PrimaryButton";

export default function ProductWarehouseScreen() {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<main>
			<h1>Product warehouse</h1>
			<PrimaryButton onClick={() => navigate("new", { state: { background: location } })}>
				New Product
			</PrimaryButton>
		</main>
	);
}
