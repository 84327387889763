import { useState } from "react";
import { Auth } from "aws-amplify";
import { useLocation, useNavigate } from "react-router-dom";
import { passwordValidatorSignup as passwordValidator, nameValidator } from "../validators.js";

import TextInput from "../../components/Inputs/Text/TextInput";
import PrimaryButton from "../../components/Buttons/Primary/PrimaryButton";
import PasswordInput from "../../components/Inputs/Password/PasswordInput.js";

export default function CompleteSignupForm() {
	const location = useLocation();
	const navigate = useNavigate();

	const [throttle, setThrottle] = useState(false);
	const [passwordError, setPasswordError] = useState();
	const [firstNameError, setFirstNameError] = useState();
	const [lastNameError, setLastNameError] = useState();

	function validateInputs(password, firstName, lastName) {
		const passwordError = passwordValidator(password);
		setPasswordError(passwordError);
		const firstNameError = nameValidator(firstName);
		setFirstNameError(firstNameError);
		const lastNameError = nameValidator(lastName);
		setLastNameError(lastNameError);

		return passwordError || firstNameError || lastNameError ? false : true;
	}

	function completeSignup(newPassword, firstName, lastName) {
		setThrottle(true);
		const email = location.state.email;
		const password = location.state.password;

		Auth.signIn(email, password)
			// Signin to get CognitoUser object
			.then((user) => {
				// Actual function responsible for submitting details
				Auth.completeNewPassword(user, newPassword, {
					given_name: firstName,
					family_name: lastName,
				})
					.then(() => navigate("/"))
					.catch((error) => setPasswordError(error));
			})
			.catch((error) => console.log(error))
			.finally(() => setThrottle(false));
	}

	function handleSubmit(event) {
		event.preventDefault();
		const data = new FormData(event.target);
		const password = data.get("password");
		const firstName = data.get("firstName");
		const lastName = data.get("lastName");

		const isValid = validateInputs(password, firstName, lastName);
		if (isValid && !throttle) completeSignup(password, firstName, lastName);
	}

	return (
		<form className="auth-form" onSubmit={handleSubmit}>
			<TextInput name="firstName" placeholder="First name" error={firstNameError} />
			<TextInput name="lastName" placeholder="Last name" error={lastNameError} />
			<PasswordInput error={passwordError} autocomplete="new-password" />

			<PrimaryButton>Sign up</PrimaryButton>
		</form>
	);
}
