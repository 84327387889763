import { useState } from "react";
import { Auth } from "aws-amplify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { emailValidator, passwordValidatorLogin as passwordValidator } from "../validators";

import TextInput from "../../components/Inputs/Text/TextInput";
import PasswordInput from "../../components/Inputs/Password/PasswordInput";
import PrimaryButton from "../../components/Buttons/Primary/PrimaryButton";

export default function LoginForm() {
	const navigate = useNavigate();
	const location = useLocation();

	const [throttle, setThrottle] = useState(false);
	const [emailError, setEmailError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);

	function validateInputs(email, password) {
		const emailError = emailValidator(email);
		setEmailError(emailError);
		const passwordError = passwordValidator(password);
		setPasswordError(passwordError);

		return emailError || passwordError ? false : true;
	}

	function handleAuthError(response) {
		switch (response?.challengeName || response?.message) {
			case "User does not exist.":
				setEmailError("No account associated with email");
				break;
			case "Incorrect username or password.":
				setPasswordError("Incorrect password");
				break;
			case "User is not confirmed.":
				setEmailError("User is not confirmed");
				break;
			default:
				console.log(response);
		}
	}

	function handleSubmit(event) {
		event.preventDefault();
		// Get form data
		const data = new FormData(event.target);
		const email = data.get("email");
		const password = data.get("password");
		// login only if inputs are valid
		const isValid = validateInputs(email, password);
		if (isValid && !throttle) login(email, password);
	}

	function redirectToDestination() {
		// Navigate to location the user came from, or navigate to root of app.
		const destination = location.state?.from?.pathname || "/";
		navigate(destination);
	}

	function handleSignInResponse(user, email, password) {
		if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
			navigate("/complete-signup", { state: { email: email, password: password, from: "login" } });
		} else {
			redirectToDestination();
		}
	}

	function login(email, password) {
		setThrottle(true);
		Auth.signIn(email, password)
			.then((user) => handleSignInResponse(user, email, password))
			.catch(handleAuthError)
			.finally(() => setThrottle(false));
	}

	return (
		<form className="auth-form" onSubmit={handleSubmit}>
			<TextInput type="email" name="email" placeholder="Email address" error={emailError} />
			<PasswordInput error={passwordError} autocomplete="current-password" />

			<PrimaryButton>Log in</PrimaryButton>

			<span className="form__text">
				Forgotten your password? <Link to="/forgot-password">Reset password</Link>
			</span>
		</form>
	);
}
