import "./Drawer.scss";
import { useState } from "react";

export default function Drawer({ children, className }) {
	const [isOpen, setIsOpen] = useState(false);

	function toggleDrawer() {
		setIsOpen(!isOpen);
	}

	return (
		<>
			<nav className={`drawer ${isOpen && "open"} ${className && className}`}>{children}</nav>

			<button className="drawer__toggle" onClick={toggleDrawer}>
				Toggle Drawer
			</button>
		</>
	);
}
