import "./PasswordInput.scss";
import { useState } from "react";

import { ReactComponent as ShowIcon } from "../../../assets/icons/eye.svg";
import { ReactComponent as HideIcon } from "../../../assets/icons/eye-off.svg";
import ErrorMessage from "../../Error/ErrorMessage";

export default function PasswordInput({ error, autocomplete }) {
	const [showPassword, setShowPassword] = useState(false);
	const placeholder = autocomplete === "new-password" ? "New password" : "Enter password";

	function toggleShow() {
		setShowPassword(!showPassword);
	}

	return (
		<div className="text-input text-input--password">
			<label className="text-input__inner">
				<input
					className="text-input__input"
					type={showPassword ? "text" : "password"}
					name="password"
					placeholder=" "
					formNoValidate={true}
					data-error={error && true}
					autoComplete={autocomplete && autocomplete}
				/>
				<span className="text-input__placeholder">{placeholder}</span>
				<button type="button" className="toggle-show-password" onClick={toggleShow}>
					{showPassword ? <HideIcon /> : <ShowIcon />}
				</button>
			</label>
			<ErrorMessage message={error} />
		</div>
	);
}
