import "./ForgotPasswordScreen.scss";
import { useState } from "react";

import BackButton from "../../components/Buttons/Back/BackButton";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm";

export default function ForgotPasswordScreen() {
	const [codeSent, setCodeSent] = useState(false);
	const [email, setEmail] = useState(null);

	return (
		<main className="forgot-password-screen">
			<BackButton />

			{!codeSent ? (
				// Step 1: Send code to email
				<Section
					title="Forgot Password"
					text="Enter the email adress asociated with your account. We'll send you the next steps to
					reset your password."
					formComponent={<ForgotPasswordForm setCodeSent={setCodeSent} setEmail={setEmail} />}
				/>
			) : (
				// Step 2: Reset password using correct code
				<Section
					title="Reset Password"
					text="We have sent a confirmation code to your email. Enter the recieved code and your new
            password."
					formComponent={<ResetPasswordForm email={email} />}
				/>
			)}
		</main>
	);
}

function Section({ title, text, formComponent }) {
	return (
		<section>
			<h2>{title}</h2>
			<p>{text}</p>
			{formComponent}
		</section>
	);
}
