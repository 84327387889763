import "./BackButton.scss";
import { ReactComponent as BackIcon } from "../../../assets/icons/arrow-left.svg";
import { Link } from "react-router-dom";

export default function BackButton() {
	return (
		<Link to={-1} className="back-button">
			<BackIcon />
			Go back
		</Link>
	);
}
