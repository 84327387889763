import "./UploadModelForm.scss";
import uuid from "react-uuid";

import { useState } from "react";
import { useAuth } from "../../../auth/authContext";
import PrimaryButton from "../../Buttons/Primary/PrimaryButton";
import SecondaryButton from "../../Buttons/Secondary/SecondaryButton";

export default function UploadModelForm() {
	const [file, setFile] = useState();
	const auth = useAuth();

	function getPresignedUrl() {
		const url = "https://z66gfzrf0d.execute-api.eu-west-2.amazonaws.com/prod/GetProductUpload";
		const jwtToken = auth.signInUserSession.idToken.jwtToken;
		const product_uuid = uuid();
		const variant_uuid = uuid();
		const file_name = uuid() + ".zip";
		const user_id = auth.attributes.sub;

		const data = {
			user_id,
			product_uuid,
			variant_uuid,
			file_name,
		};

		return fetch(url, {
			method: "post",
			body: JSON.stringify(data),
			headers: new Headers({
				Authorization: jwtToken,
			}),
		})
			.then((response) => response.json())
			.then((data) => data.upload_url)
			.catch((error) => console.log(error));
	}

	function uploadFile(event) {
		event.preventDefault();
		getPresignedUrl().then((response) => {
			// Initiate PUT request to upload file
			const url = response;
			fetch(url, {
				method: "put",
				body: file,
				headers: new Headers({
					"Content-Type": "multipart/form-data",
				}),
			})
				.then(() => {
					// File uploaded successfully
					console.log("File upload successful");
				})
				.catch((error) => {
					// Error uploading
					console.log(error);
				});
		});
	}

	return (
		<form id="upload-model" className="upload-model" onSubmit={uploadFile}>
			<label className="upload-model__label">
				Choose a file or drag here
				<input
					type="file"
					accept=".gltf, .glb, .usdz, .fbx"
					onChange={(event) => setFile(event.target.files[0])}
				/>
			</label>
			<PrimaryButton>Upload</PrimaryButton>
			<SecondaryButton>Cancel</SecondaryButton>
		</form>
	);
}
