import { useAuth } from "../../auth/authContext";

export default function OverviewScreen() {
	const auth = useAuth();

	return (
		<main className="overview-screen">
			<h2>Overview</h2>
			<p>Logged in: {auth?.username}</p>
			<p>User ID: {auth?.attributes.sub}</p>
		</main>
	);
}
