import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";

import { Amplify } from "aws-amplify";
import { config } from "./auth/cognitoConfig";
import { AuthProvider } from "./auth/authContext";
import { BrowserRouter } from "react-router-dom";

import "./base/reset.scss";
import "./base/mixins.scss";
import "./base/helpers.scss";
import "./base/typography.scss";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

Amplify.configure(config);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<AuthProvider>
				<App />
			</AuthProvider>
		</BrowserRouter>
	</React.StrictMode>
);
