import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../auth/authContext";

import AppLayout from "../layouts/App/AppLayout";
import DashboardScreen from "../screens/Overview/OverviewScreen";
import LoginScreen from "../auth/Login/LoginScreen";
import SignupScreen from "../auth/Signup/SignupScreen";
import ForgotPasswordScreen from "../auth/ForgotPassword/ForgotPasswordScreen";
import CompleteSignupScreen from "../auth/CompleteSignup/CompleteSignupScreen";
import ProductWarehouseScreen from "../screens/ProductWarehouse/ProductWarehouseScreen";
import NewProductScreen from "../screens/NewProduct/NewProductScreen";

export default function App() {
	const auth = useAuth();
	const location = useLocation();
	const background = location?.state?.background;

	return (
		<>
			{auth ? (
				<AppLayout>
					<Routes location={background || location}>
						<Route path="/" element={<DashboardScreen />} />
						<Route path="/products" element={<ProductWarehouseScreen />} />
						<Route path="/products/new" element={<NewProductScreen />} />
					</Routes>

					{background && (
						<Routes>
							<Route path="/products/new" element={<NewProductScreen />} />
						</Routes>
					)}
				</AppLayout>
			) : (
				<Routes>
					<Route path="/" element={<Navigate to="/login" />} />
					<Route path="/login" element={<LoginScreen />} />
					<Route path="/signup" element={<SignupScreen />} />
					<Route path="/forgot-password" element={<ForgotPasswordScreen />} />
					<Route path="/complete-signup" element={<CompleteSignupScreen />} />
				</Routes>
			)}
		</>
	);
}
