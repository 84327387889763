import "./CompleteSignupScreen.scss";
import BackButton from "../../components/Buttons/Back/BackButton";
import CompleteSignupForm from "./CompleteSignupForm";

export default function CompleteSignupScreen() {
	return (
		<main className="complete-signup-screen">
			<BackButton />
			<section className="complete-signup-section">
				<h2>Complete Signup</h2>
				<p>
					You're account was created by an admin. As this is your first time logging in, you
					are required to set your password and any other required attributes to keep your
					account active.
				</p>
				<CompleteSignupForm />
			</section>
		</main>
	);
}
