import "./ErrorMessage.scss";
import { ReactComponent as ErrorIcon } from "../../assets/icons/alert-circle.svg";

export default function ErrorMessage({ message }) {
	return (
		message && (
			<div className="error-message">
				<ErrorIcon className="error-message__icon" />
				<span className="error-message__text">{message}</span>
			</div>
		)
	);
}
